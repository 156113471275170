import { useHistory } from 'react-router-dom'
import { DISCOUNT_CODE_TYPE } from 'types'
import { makeQueryPath, useClearDiscountCodeAndRedirect } from 'utils'

const useDiscountCodeHandler = () => {
  const history = useHistory()
  const clearDiscountCodeAndRedirect = useClearDiscountCodeAndRedirect()

  const handleDiscountCode = ({
    promoCode = null,
    activationCode = null,
    discountCodeDetails = null,
    basePath,
  }) => {
    if (!promoCode && !activationCode && discountCodeDetails) {
      if (discountCodeDetails.autoRenewRequired) {
        clearDiscountCodeAndRedirect(basePath)
      }
      return
    }

    const codeType = activationCode
      ? DISCOUNT_CODE_TYPE.ACTIVATION
      : DISCOUNT_CODE_TYPE.PROMO
    history.replace(
      makeQueryPath(basePath, { [codeType]: activationCode ?? promoCode }),
    )
  }

  return handleDiscountCode
}

export default useDiscountCodeHandler
