import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as memberSelectors } from 'member-reducer'
import { AppliedDiscountCodeType } from 'types'
import { DiscountCodeForm } from 'member-portal/forms'
import {
  getInitialDiscountCodeValues,
  handleSubmitFailWithFlashMessage,
  useClearDiscountCodeAndRedirect,
  useDiscountCodeHandler,
} from 'utils'

const propTypes = {
  basePath: PropTypes.string.isRequired,
  discountCodeDetails: AppliedDiscountCodeType,
  formName: PropTypes.string.isRequired,
  isRecreationalCard: PropTypes.bool,
  isTrialMembershipsActive: PropTypes.bool,
  promoCodeIsTrial: PropTypes.bool,
}

function DiscountCode({
  basePath,
  discountCodeDetails,
  formName,
  isRecreationalCard,
  isTrialMembershipsActive,
  promoCodeIsTrial,
}) {
  const handleDiscountCode = useDiscountCodeHandler()
  const clearDiscountCodeAndRedirect = useClearDiscountCodeAndRedirect()

  const clearDiscountCode = () => {
    clearDiscountCodeAndRedirect(basePath)
  }

  const handleSubmit = ({ activationCode, promoCode }) => {
    handleDiscountCode({
      promoCode,
      activationCode,
      basePath,
    })
  }

  return (
    <DiscountCodeForm
      // form name must be provided to eliminate race condition
      // when switching between views that both use DiscountCode
      appliedDiscountCodeDescription={discountCodeDetails?.description}
      clearDiscountCode={clearDiscountCode}
      codeFieldType={discountCodeDetails?.type}
      initialValues={getInitialDiscountCodeValues(discountCodeDetails)}
      isRecreationalCard={isRecreationalCard}
      name={formName}
      onSubmit={handleSubmit}
      onSubmitFail={handleSubmitFailWithFlashMessage}
      promoCodeIsTrial={isTrialMembershipsActive && promoCodeIsTrial}
    />
  )
}

DiscountCode.propTypes = propTypes

function mapStateToProps(state) {
  return {
    isRecreationalCard: memberSelectors.isRecreationalCard(state),
    isTrialMembershipsActive: memberSelectors.isTrialMembershipsActive(state),
  }
}

export default compose(connect(mapStateToProps, null))(DiscountCode)
