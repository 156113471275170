import { DISCOUNT_CODE_AUTO_RENEW_REQUIRED, MEMBERSHIP_TYPE } from 'types'
import { isTrialPromoType } from 'utils'

const processPromoCodeResponse = (promoResponse, isTrialMembershipsActive) => {
  const {
    ongoing_promo_type__c: onGoingPromoType,
    promotion_type__c: promoType,
    related_product: relatedProduct,
  } = promoResponse

  const isTrialType = isTrialPromoType(promoType)
  const isTrial = isTrialMembershipsActive && isTrialType
  const isAutoRenewRequired =
    DISCOUNT_CODE_AUTO_RENEW_REQUIRED.includes(onGoingPromoType)

  return {
    shouldAutoRenew: isTrial || isAutoRenewRequired,
    trialMembershipName: isTrial ? MEMBERSHIP_TYPE.TRIAL_MEMBERSHIP : null,
    trialMembershipType: isTrial ? relatedProduct.name : null,
  }
}

export default processPromoCodeResponse
