import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import * as memberActions from 'member-actions'

const useClearDiscountCodeAndRedirect = () => {
	const dispatch = useDispatch()
	const history = useHistory()

	const clearDiscountCodeAndRedirect = (basePath) => {
		dispatch(memberActions.clearDiscountCodeStateAndLS())
		history.replace(basePath)
	}

	return clearDiscountCodeAndRedirect
}

export default useClearDiscountCodeAndRedirect
